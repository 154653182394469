import React, { useEffect } from 'react';

function API() {
  //show user places if logged in and notifications about places
  //if not show blurb about places then a list of places
//get all places and display them with links to the places and buttons to edit if they are logged in
//nearby places using coords

 
useEffect(() => {
  document.title = 'API - Zeitgeists';
  window.scrollTo(0, 0);

}, []);

  



  return (
    <div className='mt-16 text-center'>
<p>
To access the Data simply append /feed to any region, place, organizer, or series page. For example, to access the data for the <a href='/region/los-anageles'>Los Angeles</a> region, you would go to <a href='/region/los-angeles/feed'>/region/los-anageles/feed</a>. This will return a hosted nightly updated JSON object with all of the relevant data for that page. The data follows the @graph format of schema.org with a zeitgeists id saved as identifier. For more information on the shema, see the <a href='https://schema.org/'>schema.org</a> documentation. 
</p>

    </div>


  );
}

export default API;



